<template>
  <div>
    <div class="workdayShopD">
      <div class="workdayShop">
        <div class="workdayShop_box" v-for="(item, index) in scheme" :key="index" >
          <div class="workdayShop_box_top">
            <h2 @click="outlet_pass(item)">{{ item.plan_name }} {{Number(index)+1}}</h2>
            <span @click="removeF(item)">X</span>
          </div>
          <div class="workdayShop_box_cen">
            <div class="workdayShop_cen_div">
              <div class='PlanStore' v-for="(i,index) in item.shop_list" :key="index">
                <span></span>
                <h3>{{i.shop_name}}</h3>
                <p @click="delete_plan(i)">删除</p>
              </div>
            </div>
          </div>
          <div class="workdayShop_button">
            <a @click="new_store(item)">新增门店</a>
          </div> 
        </div>
      </div>
      <div class="newly_scheme" @click="newly_scheme()">
        <img src="../../../assets/img/tianjiaS.png" alt="" />
        <h4>新增</h4>
      </div>
    </div>
    <div >
        <div class="shade" @click="giabain()" v-if="shade"></div>
        <div class="shop" v-if="shop" >
            <h3>选择店铺</h3>
            <p @click="shop_s">X</p>
            <ul>
                <li v-for="(item,index) in store" :key="index" @click="addition(item)">{{item.shop_name}}</li>
            </ul>
        </div>
    </div>
    <div class="tishi">
      提示： 若员工轮休采取轮休制，则设置周一到周日均为工作日
    </div>
  </div>
</template>
<script>
import {
  query_workday_plan_list,
  insert_workday_plan,
  delete_workday_plan,
  insert_workday_plan_shop,
  delete_workday_plan_shop,
  query_shop_info_list,

} from '@api/pulic'
export default {
  data() {
    return {
      scheme: [],
      codeData: '',
      shade: false,
      shop: false,

      store: [],

      outlet:[],
      boo: '',
      ent_idS: '',
      plan_idC: '', // 新增方案门店列表 存plan_id方案 id

      number:'' // 方案名称加一
    }
  },
  mounted() {},
  created() {
    // 渲染方案列表
    this.list_new()
    // 查询门店信息列表

  },

  methods: {

    // 渲染方案列表
    list_new(){
      query_workday_plan_list({
          data: {
            ent_id: this.$store.state.ent_id,
          }
      }).then(res => {
        console.log(res)
        console.log(res.body.data)
        this.scheme = res.body.data
      })
    },


    giabain() {
      this.shop = false
      this.shade = false
    },
    shop_s() {
      this.shop = false
      this.shade = false
    },

    outlet_pass(i){
      console.log(i)
      console.log(i.plan_id)
      this.$router.push({ name: 'workday', params: { sss: i.plan_id } })
    },

    // new_store 新增门店方案门店
    new_store(item) {
      this.shop = true
      this.shade = true
      query_shop_info_list({
      data: {
        ent_id: this.$store.state.ent_id,
      }
      }).then(res => {
        console.log(res.body.data[0].shop_name , ' s')
        this.store = res.body.data
        this.plan_idC = item.plan_id
        console.log(this.plan_idC)
        console.log(res.body.data)
      })
      // inseryt_workday_plan_shop({
      //     data:{
      //         ent_id: this.$store.state.ent_id,
      //         plan_id: item.plan_id,
      //         shop_id: this.shop_idX // sssssssssssssssssssssssssssssssssssssssssssssssssssssssss有问题
      //     }
      // }).then( res => {
      //     console.log(res)
      // })
      console.log(item.plan_id)
    },

     // 添加到门店列表
    addition(item){
      console.log(item)
      console.log(item.shop_id)
      console.log(this.plan_idC)
      insert_workday_plan_shop({
        data:{
          ent_id:this.$store.state.ent_id,
          plan_id: this.plan_idC.toString(),
          shop_id: item.shop_id.toString(),
          user_id:this.$store.state.user_id
        }
      }).then( res => {
        if(res.code == 200){
          this.$message({
            message: '成功添加到门店',
            type: 'success'
          })
          this.shop = false
          this.shade = false
          this.list_new()
        } else{
           this.$message({
            message: '添加到门店',
            type: 'success'
          })
        }
      })
    },

    // 删除门店
    delete_plan(item) {
      delete_workday_plan_shop({
          data:{
            ent_id: this.$store.state.ent_id,
            plan_id: item.plan_id.toString(),
            shop_id: item.shop_id.toString(),
            user_id:this.$store.state.user_id
          }
      }).then( res =>{
        console.log(res)
         if(res.code == 200){
          this.$message({
            message: '成功删除门店',
            type: 'success'
          })
          this.shop = false
          this.shade = false
          this.list_new()
        } else{
           this.$message({
            message: '删除门店失败',
            type: 'success'
          })
        }
      })
    },
    // 添加方案
    newly_scheme() {
      this.createCode()

      insert_workday_plan({
        data: {
          ent_id: this.$store.state.ent_id,
          user_id:this.$store.state.user_id,
          plan_id: this.codeData.toString(), // 方案id 随机的
          plan_name: '方案', // 方案名称
          shop_id: '' // 门店id,逗号分割
        }
      }).then(res => {
        console.log(res)
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: '添加成功!'
          })
          this.tianjia()
          console.log(res)
        }
      })
    },
    // 删除方案
    removeF(item) {
      this.$confirm('此操作将永久删除该方案, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delete_workday_plan({
            data: {
              ent_id: this.$store.state.ent_id,
              plan_id: item.plan_id,
              user_id:this.$store.state.user_id
            }
          }).then(res => {
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.tianjia()
            } else {
              this.$message({
                type: 'success',
                message: '删除失败!'
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })

      console.log(item)
    },
    // 随机数 添加方案随机数
    createCode() {
      var code = ''
      // 设置长度，这里看需求，我这里设置了4
      var codeLength = 6
      // 设置随机字符
      var random = new Array(0, 1, 2, 3, 4, 5, 6, 7, 8, 9)
      // 循环codeLength 我设置的4就是循环4次
      for (var i = 0; i < codeLength; i++) {
        // 设置随机数范围,这设置为0 ~ 36
        var index = Math.floor(Math.random() * 9)
        // 字符串拼接 将每次随机的字符 进行拼接
        code += random[index]
      }
      // 将拼接好的字符串赋值给展示的code
      this.codeData = code   // 随机数
      console.log(this.codeData)
    },

    // 添加成功函数
    tianjia(){
       query_workday_plan_list({
        data: {
            ent_id: this.$store.state.ent_id,
          }
        }).then(res => {
          console.log(res)
          console.log(res.body.data)
          this.scheme = res.body.data
          this.number = res.body.data.length + 1
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.el-main {
  background: #f2f6fc;
  overflow: auto;
}
.shop{
    width: 400px;
    height: 350px;
    background: #fff;
    z-index: 22;
    position: fixed;
    left: 50%;
    top: 50%;
    padding: 20px 10px 0;
    box-sizing: border-box;
    transform: translate(-50%,-50%);
    border-radius: 10px;


    h3{
        font-size: 22px;
        color: #333;
        text-align: center;
    }
    p{
        position: absolute;
        font-size: 20px;
        right: 10px;
        top: 10px;
        color: #333;
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        border: 1px #fe5746 solid;
        color: #fe5746;
        border-radius: 50%;
    }
    ul{
        display: flex;
        flex-wrap: wrap;
        margin-top: 40px;
        li{
            font-size: 16px;
            margin-bottom: 20px;
            width: 33.33%;
            text-align: center;
            color: #333;
        }
    }
}
.workdayShopD {
  display: flex;
  flex-wrap: wrap;
  background: #fff;
  -moz-box-shadow: 0px 0px 10px #e8e8eb;
  -webkit-box-shadow: 0px 0px 10px #e8e8eb;
  box-shadow: 0px 0px 10px #e8e8eb;
  padding: 40px 40px 200px 40px;
  box-sizing: border-box;
}
.workdayShop {
  display: flex;
  flex-wrap: wrap;
  .workdayShop_box {
    width: 368px;
    height: 240px;
    margin: 0 20px 20px 0;
    box-shadow: 0px 9px 18px 0px rgba(101, 101, 101, 0.05),
      0px 0px 1px 0px rgba(4, 0, 0, 0.2);
    border-radius: 16px;
    display: block;
    .workdayShop_box_top {
      width: 100%;
      height: 60px;
      background: linear-gradient(180deg, #fc4353, #ff5e41);
      border-radius: 16px 16px 0 0;
      position: relative;
      h2 {
        font-size: 19px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #f9f9f9;
        text-align: center;
        line-height: 60px;
      }
      span {
        position: absolute;
        right: 16px;
        top: 10px;
        color: #fff;
        font-size: 20px;
      }
    }
    .workdayShop_box_cen {
      height: 100px;
      overflow-y: auto;
      padding: 18px 34px 4px 23px;
      .workdayShop_cen_div {
        margin-bottom: 16px;
        .PlanStore{
          width: 100%;
          display: flex;
          margin-bottom: 16px;
        }
        span {
          width: 11px;
          height: 11px;
          background: #e5e5e5;
          border-radius: 50%;
          display: block;
          margin-top: 4px;
          &:hover {
            cursor: pointer;
          }
        }
        h3 {
          font-size: 17px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #969696;
          margin: 0 90px 0 6px;
          &:hover {
            cursor: pointer;
          }
        }
        p {
          font-size: 14px;
          font-family: PingFang SC;
          color: rgba(254, 66, 66, 1);
          margin-top: 2px;
          display: block;
        }
      }
    }
    .workdayShop_button {
      width: 96%;
      margin: 0 auto;
      border-top: 1px solid #f0f0f0;
      padding: 10px 0;
      a {
        width: 99px;
        height: 35px;
        background: #fe5746;
        border-radius: 6px;
        text-align: center;
        color: #fff;
        display: block;
        margin: 0 auto;
        font-size: 16px;
        line-height: 35px;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
.newly_scheme {
  width: 368px;
  margin-right: 20px;
  display: flex;
  box-shadow: 0px 9px 18px 0px rgba(101, 101, 101, 0.09),
    0px 0px 1px 0px rgba(4, 0, 0, 0.6);
  border-radius: 16px;
  height: 240px;
  justify-content: center;
  align-items: center;
  h4 {
    color: #ff4c4c;
    font-size: 20px;
  }
  img {
    width: 23px;
    height: 23px;
    margin-right: 6px;
  }
}
.tishi {
  margin-top: 40px;
  color: #999999;
  font-size: 14px;
}

</style>
